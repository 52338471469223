<template>
  <div class="single-layout">
    <display
      :key="index"
      :class="gridItem.css"
      :parent-category-id="gridItem.parentCategoryId"
      v-for="(gridItem, index) in gridLayout">
    </display>
  </div>
</template>

<script>
  import DigitalSignageMixin from '../../../mixin/digital-signage'

  export default {
    watch: {
      category: {
        deep: true,
        immediate: true,
        handler() {
          this.cssClasses = ['']
          this.prepareDigitalSignageObjects(1)
        }
      }
    },
    mixins: [
      DigitalSignageMixin
    ],
    inject: [
      '$api',
    ]
  }
</script>

<style lang="scss" scoped>
  .single-layout {
    @apply
      grid
      w-full
      h-full
      grid-rows-1
      grid-cols-1;
  }
</style>